import React, { Fragment } from "react";

import classes from "./aboutMe.module.css";
import PersonalData from "../../Data/PersonalData";
import SocialLinks from "../SocialLinks/SocialLinks";
import Button from "../UI/Button";
import GetInTouch from "../Get In Touch/GetInTouch";
import { useSelector } from "react-redux";

import ImageUrl from "../../Data/dp.jpg";

const AboutMe = () => {
  const uiColor = useSelector((state) => state.uiColor);
  return (
    <Fragment>
      <div className="common_top_gap">
        <div className={classes.contactMe} id="getInTouch">
          <div className={classes.avatar}>
            <img
              src={ImageUrl}
              alt="Loading ..."
              style={{ borderColor: uiColor }}
            />
          </div>
          <div className={classes.contactCard}>
            <h1 style={{ color: uiColor }}>About Me</h1>
            <div>
              {/* {PersonalData.aboutMe} */}
              {PersonalData.aboutMe?.map((a) => (
                <p>{a}</p>
              ))}
            </div>
            <div className={classes.contactLinks}>
              <SocialLinks className={classes.links} />
            </div>
            <div className={classes.resume_button}>
              <a  href={PersonalData.resumeLink} target="_blank noreferrer">
                <Button>See My Resume</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="common_top_gap">
        <GetInTouch />
      </div>
    </Fragment>
  );
};
export default AboutMe;
