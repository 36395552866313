// import resume from '../assets'
const PersonalData = {
  firstName: "Aman Kumar",
  lastName: "Pandey",
  // nickName:"<  killer - abhi >",
  // mobNo: "+91 9993935261",
  email: "amankumarcs519@gmail.com",
  address: "India",
  aboutMe: [
    "Hi, I’m Aman Kumar Pandey, a Software Developer with 2.1 years of experience. I specialize in backend development and am skilled in React.js, AWS, MySQL, MongoDB, and PostgreSQL.",
    "I create scalable backend solutions, configure AWS services, and use S3 for data storage.",
    "I also have experience in team leadership, project management, and integrating payment gateways like Stripe.", 
    "Proficient in Git, GitHub, and deployment via Heroku, Netlify, and AWS, I'm eager to apply my skills to new challenges and innovative solutions.",
   
  ],
  resumeLink: 'https://drive.google.com/file/d/1RcbJC9eFJ5CLehqSUnhxaVpXnZpnzVXe/view?usp=sharing',
  imageUrl: "",
};
export const autoTypeData = ["Full Stack Developer"];
export default PersonalData;
