// const githubLink="https://github.com/killer-abhi";
const instaLink="https://www.instagram.com/__pandey___ji_____/?igsh=MW9jaWJ2bTB1cDg1bw%3D%3D";
const linkedInLink="https://www.linkedin.com/in/aman-k-pandey";
// const twitterLink="https://twitter.com/ABHIDK0914";
const emailLink="mailto:pandeyaman997@gmail.com";

const profileLinks={
    // githubLink,
    instaLink,
    linkedInLink,
    // twitterLink,
    emailLink
}
const SocialData=profileLinks;
export default SocialData;

